import { FeatureName } from "@/models/userProfile.model";
import { AppRoutes } from "@/constants/appRoutes";

const PLAYBACK_PAGE_ROUTE = "interactions";

const menuItems = [
  {
    name: "Speech Analytics",
    path: AppRoutes.speechAnalytics,
    icon: "icon-chart-column",
    activeIcon: "icon-chart-column",
    size: "44px",
    featureName: null
  },
  {
    name: "Success Intelligence",
    path: AppRoutes.successIntelligence,
    icon: "icon-star",
    activeIcon: "icon-star-fill",
    size: "22px",
    featureName: FeatureName.successIntelligence
  },
  {
    name: "Playback",
    path: AppRoutes.playback,
    icon: "icon-play-empty-outline",
    activeIcon: "icon-play-2",
    size: "18px",
    featureName: null
  },
];

export { menuItems, PLAYBACK_PAGE_ROUTE };
